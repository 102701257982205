import React from 'react'
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { Container, Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import SectionsWrapper from 'components/SectionsWrapper';
import { useLayoutContext } from 'context/LayoutContext';
import Section from 'components/Section'
import Headline from 'components/Headline'
import SmallPrint from 'components/SmallPrint'
import externalLinks from 'utils/externalLinks'
import SEO from 'components/SEO'

const CommissionsAndFeesWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;
  padding-bottom: 2rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
     margin-top: 13.0625rem;
   `)}
  }
`;

const HeadlineSection = styled(Section)`
  ${Headline} {
    text-align: left;
    padding-bottom: 1rem;
  }
`;

const StyledRow = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  h4 {
    color: ${prop('theme.colors.gray')};
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1.5rem;
    }

    &.fees-header {
      @media (max-width: ${prop('theme.breakpoints.md')}) {
        margin-bottom: 0;
      }
    }
  }

  .col-md-6 {
    &:first-of-type,
    &:last-of-type {
      padding-left: 0;
      padding-right: 0;
    }

    &:first-of-type {
      margin-bottom: 2rem;

      @media (min-width: ${prop('theme.breakpoints.md')}) {
        margin-bottom: 0;
        padding-right: 0.9375rem;
      }
    }

    &:last-of-type {
      @media (min-width: ${prop('theme.breakpoints.md')}) {
        padding-left: 0.9375rem;
      }
    }
  }

  .col-lg-6 {
    &:first-of-type,
    &:last-of-type {
      padding-left: 0;
      padding-right: 0;
    }

    &:first-of-type {
      margin-bottom: 2rem;

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        padding-right: 0.9375rem;
        margin-bottom: 0;
      }
    }

    &:last-of-type {
      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        padding-left: 0.9375rem;
      }
    }
  }
`;

const StyledDiv = styled.div`
  @media (min-width: ${prop('theme.breakpoints.md')}) {
    min-height: 10rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  sup {
    color: ${prop('theme.colors.primaryRed')};
  }

  &&& {
    a {
      color: ${prop('theme.colors.primaryRed')};
      font-weight: 700;
      text-decoration: none;
      font-style: italic;

      &:hover {
        color:${prop('theme.colors.gray')};
        text-decoration: none;
      }
    }
  }

  p {
    margin: 0;

    &.pricing-type {
      color: ${prop('theme.colors.gray')};
      font-size: 0.875rem;
    }

    &.pricing-cost {
      color: ${prop('theme.colors.black')};
      font-weight: 700;
    }

    &.pricing-zero-cost {
      color: ${prop('theme.colors.primaryRed')};
      font-weight: 700;
    }

    &.pricing-note {
      color: ${prop('theme.colors.gray')};
      font-size: 0.875rem;
      font-style: italic;
    }

    &.pricing-note--cont {
      color: ${prop('theme.colors.primaryRed')};
      font-size: 0.875rem;
      font-style: italic;
    }
  }
`;

const MarginRatesTable = styled.table`
  font-family: ${prop('theme.fonts.default')};
  line-height: 2;
  width: 100%;

  .debit-balance-bold {
    font-weight: 700;
  }

  tr {
    border-bottom: 0.0625rem solid ${prop('theme.colors.lighterGray')};

    th {
      font-weight: normal;

      &:first-child {
        width: 50%;
      }
    }

    td, th {
      font-size: 0.75rem;

      @media (min-width: ${prop('theme.breakpoints.xs')}) {
        font-size: 0.875rem;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }
`;

const MarginRateFootnote = styled(SmallPrint)`
  color: ${prop('theme.colors.primaryRed')};
  font-style: normal;
  line-height: normal;
  margin-top: 0.5rem;
`;

const FeesCol = styled(Col)`
  font-family: ${prop('theme.fonts.default')};
  line-height: 2;
`;

const FeeRow = styled.div`
  display: flex;
  font-size: 0.75rem;
  justify-content: space-between;

  @media (min-width: ${prop('theme.breakpoints.xs')}) {
    font-size: 0.875rem;
  }

  ${ifProp('title', css`
    font-weight: 700;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin-top: 0.875rem;
    }
  `)}

  sup {
    color: ${prop('theme.colors.primaryRed')};
  }
`;

const SingleListedFeesTable = styled.table`
  font-family: ${prop('theme.fonts.default')};
  width: 100%;

  tr {
    border-bottom: 0.0625rem solid ${prop('theme.colors.lighterGray')};

    td, th {
      font-size: 0.75rem;
      font-weight: normal;

      @media (min-width: ${prop('theme.breakpoints.xs')}) {
        font-size: 0.875rem;
      }

      sup {
        color: ${prop('theme.colors.primaryRed')};
      }
    }

    th {
      &:last-child {
        text-align: right;
        width: 30%;
      }
    }

    td, th {
      &:nth-child(1) {
        padding-right: 1rem;
      }
    }

    td {
      &:nth-child(1) {
        font-weight: 700;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }
`;

const OptionsFeesCol = styled(Col)`
  ${SmallPrint} {
    font-style: normal;
  }
`;

const Disclaimer = styled.div`
  ${SmallPrint} {
    font-style: normal;
  }

  &&& {
    a {
      color: ${prop('theme.colors.primaryRed')};
      text-decoration: none;

      &:hover {
        color: ${prop('theme.colors.gray')};
        text-decoration: none;
      }
    }
  }

  h5 {
    font-weight: 600;
    color: ${prop('theme.colors.gray')};
  }

  ol {
    counter-reset: item;
    font-family: ${prop('theme.fonts.default')};
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    li {
      color: ${prop('theme.colors.lightGray')};
      counter-increment: item;
      font-size: 0.75rem;
      margin-bottom: 1rem;

      @media (min-width: ${prop('theme.breakpoints.xs')}) {
        font-size: 0.875rem;
      }

      &:before {
        color: ${prop('theme.colors.primaryRed')};
        content: counter(item);
        margin-right: 0.3125rem;
      }
    }
  }
`;

function CommissionsAndFees() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='Commissions And Fees' canonicalHref='https://tastytrade.com/commissions-and-fees' />
      <CommissionsAndFeesWrapper noGutters $showAlert={showAlert}>
        <HeadlineSection withPadding>
          <Headline>
            Commissions & Fees
          </Headline>
          <hr />
        </HeadlineSection>

        <Section withPadding>
          <Container>
            <StyledRow>
              <h4>Commission</h4>
              <Col sm={12} md={6}>
                <StyledDiv>
                  <p className='pricing-type'>Options On Stock <sup>1</sup></p>
                  <p className='pricing-cost'>$1.00 / contract to open</p>
                  <p className='pricing-zero-cost'>$0.00 commission to close</p>
                  <p className='pricing-note'>
                    All stock options trades incur a clearing fee of $0.10 per
                    contract
                  </p>
                  <p className='pricing-note--cont'>
                    Equity option commission capped at $10 per leg.
                    {' '}
                    <a
                      href={externalLinks.commissionsAndFees}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      More
                    </a>
                  </p>
                </StyledDiv>
                <StyledDiv>
                  <p className='pricing-type'>Stock <sup>2</sup></p>
                  <p className='pricing-zero-cost'>$0.00 commission to open</p>
                  <p className='pricing-zero-cost'>$0.00 commission to close</p>
                  <p className='pricing-note'>
                    All stock trades incur a clearing fee of $0.0008 per share
                  </p>
                </StyledDiv>
                <StyledDiv>
                  <p className='pricing-type'>Cryptocurrencies</p>
                  <p className='pricing-cost'>1% of total crypto purchase to open</p>
                  <p className='pricing-cost'>1% of total crypto sale to close</p>
                  <p className='pricing-note'>
                    Cryptocurrency commissions capped at $10, per order ticket.
                  </p>
                </StyledDiv>
                <StyledDiv>
                  <p className='pricing-type'>Options on Futures<sup>3</sup></p>
                  <p className='pricing-cost'>$2.50 / contract to open</p>
                  <p className='pricing-zero-cost'>$0.00 commission to close</p>
                  <p className='pricing-note'>
                    All futures options trades incur a clearing fee of $0.30 per
                    contract + exchange fees + NFA fee.
                  </p>
                </StyledDiv>
              </Col>
              <Col sm={12} md={6}>
                <StyledDiv>
                  <p className='pricing-type'>Options on Micro Futures<sup>3</sup></p>
                  <p className='pricing-cost'>$1.50 / contract to open</p>
                  <p className='pricing-zero-cost'>$0.00 commission to close</p>
                  <p className='pricing-note'>
                    All futures options trades incur a clearing fee of $0.30 per
                    contract + exchange fees + NFA fee.
                  </p>
                </StyledDiv>
                <StyledDiv>
                  <p className='pricing-type'>Futures</p>
                  <p className='pricing-cost'>$1.25 / contract to open</p>
                  <p className='pricing-cost'>$1.25 / contract to close</p>
                  <p className='pricing-note'>
                    All futures trades incur a clearing fee of $0.30 per
                    contract + exchange fees + NFA Fee.
                  </p>
                </StyledDiv>
                <StyledDiv>
                  <p className='pricing-type'>Micro Futures</p>
                  <p className='pricing-cost'>$0.85 / contract to open</p>
                  <p className='pricing-cost'>$0.85 / contract to close</p>
                  <p className='pricing-note'>
                    All futures trades incur a clearing fee of $0.30 per
                    contract + exchange fees + NFA Fee.
                    <br />
                    To view a list of available futures contracts and exchange
                    fees, please click
                    {' '}
                    <a
                      href={externalLinks.futuresContracts}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      here
                    </a>
                  </p>
                </StyledDiv>
                <StyledDiv>
                  <p className='pricing-type'>Smalls Futures</p>
                  <p className='pricing-cost'>$0.25 / contract to open</p>
                  <p className='pricing-cost'>$0.25 / contract to close</p>
                  <p className='pricing-note'>
                    All smalls futures trades incur a clearing fee of $0.05 per
                    contract + exchange fees + NFA Fee.
                    <br />
                    To view a list of available smalls futures contracts and exchange
                    fees, please click
                    {' '}
                    <a
                      href={externalLinks.smallsFuturesContracts}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      here
                    </a>
                  </p>
                </StyledDiv>
              </Col>
            </StyledRow>
            <StyledRow>
              <h4>Margin Rates</h4>
              <MarginRatesTable>
                <thead>
                  <tr>
                    <th>Debit Balance</th>
                    <th>Rate</th>
                    <th>Base Rate +/-</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='debit-balance-bold'>$0 - $24,999</td>
                    <td>8.00%</td>
                    <td>Base Rate + 1.00%</td>
                  </tr>
                  <tr>
                    <td className='debit-balance-bold'>$25,000 - $49,999</td>
                    <td>7.50%</td>
                    <td>Base Rate + 0.50%</td>
                  </tr>
                  <tr>
                    <td className='debit-balance-bold'>$50,000 - $99,999</td>
                    <td>7.00%</td>
                    <td>Base Rate</td>
                  </tr>
                  <tr>
                    <td className='debit-balance-bold'>$100,000 - $249,999</td>
                    <td>6.50%</td>
                    <td>Base Rate - 0.50%</td>
                  </tr>
                  <tr>
                    <td className='debit-balance-bold'>$250,000 - $499,999</td>
                    <td>6.00%</td>
                    <td>Base Rate - 1.00%</td>
                  </tr>
                  <tr>
                    <td className='debit-balance-bold'>$500,000 - $999,999</td>
                    <td>5.50%</td>
                    <td>Base Rate - 1.50%</td>
                  </tr>
                  <tr>
                    <td className='debit-balance-bold'>$1,000,000 +</td>
                    <td>5.00%</td>
                    <td>Base Rate - 2.00%</td>
                  </tr>
                </tbody>
              </MarginRatesTable>
              <MarginRateFootnote>
                Base Rate = 7%. Subject to change without notice
              </MarginRateFootnote>
            </StyledRow>
            <StyledRow>
              <h4 className='fees-header'>Fees</h4>
              <FeesCol sm={12} md={6}>
                <FeeRow title>
                  Banking Fees
                </FeeRow>
                <FeeRow>
                  <div>ACH Deposits & Withdrawals</div>
                  <div>FREE</div>
                </FeeRow>
                <FeeRow>
                  <div>Outgoing Domestic Wire</div>
                  <div>$25</div>
                </FeeRow>
                <FeeRow>
                  <div>Outgoing Foreign Wire</div>
                  <div>$45</div>
                </FeeRow>
                <FeeRow>
                  <div>Reorg Wire Fee</div>
                  <div>$25 minimum</div>
                </FeeRow>
                <FeeRow>
                  <div>ACH Notice of Correction</div>
                  <div>$5</div>
                </FeeRow>
                <FeeRow>
                  <div>Domestic Check</div>
                  <div>$5</div>
                </FeeRow>
                <FeeRow>
                  <div>Foreign Check</div>
                  <div>$10</div>
                </FeeRow>
                <FeeRow>
                  <div>Dividend Check</div>
                  <div>$5</div>
                </FeeRow>
                <FeeRow>
                  <div>Overnight Check Delivery - Domestic</div>
                  <div>$50</div>
                </FeeRow>
                <FeeRow>
                  <div>Overnight Check Delivery - International</div>
                  <div>$100</div>
                </FeeRow>
                <FeeRow>
                  <div>Returned Check / Wire / ACH / Recall</div>
                  <div>$30</div>
                </FeeRow>
                <FeeRow>
                  <div>Check Copies</div>
                  <div>$15</div>
                </FeeRow>
                <FeeRow>
                  <div>Stop Payment on Apex Issued Checks</div>
                  <div>$30</div>
                </FeeRow>
                <FeeRow>
                  <div>Outgoing ACAT Transfers</div>
                  <div>$75</div>
                </FeeRow>
                <FeeRow>
                  <div>Transfer and Ship Certificate</div>
                  <div>$235</div>
                </FeeRow>
                <FeeRow>
                  <div>Certificate Processing</div>
                  <div>$300 minimum</div>
                </FeeRow>
                <FeeRow>
                  <div>Certificate Processing Return</div>
                  <div>$100 minimum</div>
                </FeeRow>
                <FeeRow>
                  <div>Restricted Stock Processing</div>
                  <div>$205</div>
                </FeeRow>
                <FeeRow>
                  <div>IRA Closing Fee</div>
                  <div>$60</div>
                </FeeRow>
                <FeeRow>
                  <div>Annual IRA Maintenance Fee</div>
                  <div>FREE</div>
                </FeeRow>
                <FeeRow>
                  <div>Prepayment Fee</div>
                  <div>Greater of 0.2% or $20</div>
                </FeeRow>
                <FeeRow>
                  <div>Transfer on Death Account Transfer Fee</div>
                  <div>$50</div>
                </FeeRow>
                <FeeRow>
                  <div>Incoming & Outgoing DRS transfers</div>
                  <div>$115 per symbol</div>
                </FeeRow>
              </FeesCol>
              <FeesCol sm={12} md={6}>
                <FeeRow title>
                  Trade-Related Fees
                </FeeRow>
                <FeeRow>
                  <div>Option Exercise / Assignment</div>
                  <div>$5</div>
                </FeeRow>
                <FeeRow>
                  <div>Options Regulatory Fee</div>
                  <div>$0.02135 / contract</div>
                </FeeRow>
                <FeeRow>
                  <div>FINRA TAF <sup>4</sup> - Stock Sales</div>
                  <div>$0.000119 / share</div>
                </FeeRow>
                <FeeRow>
                  <div>FINRA TAF <sup>4</sup> - Option Sales</div>
                  <div>$0.002 / contract</div>
                </FeeRow>
                <FeeRow>
                  <div>SEC Fee <sup>5</sup></div>
                  <div>$22.10 / $1,000,000.00 in sales</div>
                </FeeRow>
                <FeeRow>
                  <div>Clearing Fees - Equities </div>
                  <div>$0.0008 / share</div>
                </FeeRow>
                <FeeRow>
                  <div>Clearing Fees - Options</div>
                  <div>$0.10 / contract</div>
                </FeeRow>
                <FeeRow>
                  <div>Clearing Fees - Futures</div>
                  <div>$0.30 / contract</div>
                </FeeRow>
                <FeeRow>
                  <div>Clearing Fees - Warrants</div>
                  <div>$20 / transaction</div>
                </FeeRow>
                <FeeRow>
                  <div>NFA Fee</div>
                  <div>$0.02 / contract</div>
                </FeeRow>
                <FeeRow>
                  <div>Voluntary Corporate Actions (Reorg/Tender Offers)</div>
                  <div>$50</div>
                </FeeRow>
                <FeeRow>
                  <div>Warrant Exercise Fee</div>
                  <div>$50 / instruction</div>
                </FeeRow>
                <FeeRow>
                  <div>Treasury Bill Fee</div>
                  <div>$25 / transaction</div>
                </FeeRow>
                <FeeRow>
                  <div>Paper Confirms</div>
                  <div>$2 / confirm</div>
                </FeeRow>
                <FeeRow>
                  <div>Paper Monthly and Quarterly Statements</div>
                  <div>$5 / statement</div>
                </FeeRow>
                <FeeRow>
                  <div>Paper Tax Statements</div>
                  <div>$5 / statement</div>
                </FeeRow>
                <FeeRow>
                  <div>Paper Prospectus</div>
                  <div>$2.50 / mailing</div>
                </FeeRow>
                <FeeRow>
                  <div>Third Party Distribution Notification</div>
                  <div>$2</div>
                </FeeRow>
                <FeeRow>
                  <div>DTC Delivery</div>
                  <div>$25</div>
                </FeeRow>
                <FeeRow>
                  <div>Mutual Fund Liquidation</div>
                  <div>$20 / fund</div>
                </FeeRow>
                <FeeRow>
                  <div>Regulatory Fee on Equity and Options Sales <sup>5</sup></div>
                  <div>(see below)</div>
                </FeeRow>
                <FeeRow>
                  <div>Non-Transferable Security Fee</div>
                  <div>$10 / mo. per security</div>
                </FeeRow>
                <FeeRow>
                  <div>ADR Fees <sup>6</sup></div>
                </FeeRow>
              </FeesCol>
            </StyledRow>
            <StyledRow>
              <OptionsFeesCol md={12} lg={6}>
                <SmallPrint dark bold>
                  Single-Listed Exchange Proprietary Index Options Fees:
                </SmallPrint>
                <SingleListedFeesTable>
                  <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Description</th>
                    <th>Fee Per Contract</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>SPX</td>
                    <td>S&P 500 Index</td>
                    <td>$0.65</td>
                  </tr>
                  <tr>
                    <td>RUT</td>
                    <td>Russell 2000 Index</td>
                    <td>$0.18</td>
                  </tr>
                  <tr>
                    <td>VIX</td>
                    <td>CBOE Market Volatility Index</td>
                    <td>$0.65</td>
                  </tr>
                  <tr>
                    <td>OEX</td>
                    <td>S&P 100 Index (American-style exercise)</td>
                    <td>$0.40</td>
                  </tr>
                  <tr>
                    <td>XEO</td>
                    <td>S&P 100 Index (European-style exercise)</td>
                    <td>$0.40</td>
                  </tr>
                  <tr>
                    <td>DJX</td>
                    <td>Dow Jones Industrial Average Index 1/100</td>
                    <td>$0.18</td>
                  </tr>
                  <tr>
                    <td>NDX</td>
                    <td>NASDAQ 100 Index</td>
                    <td>$0.18</td>
                  </tr>
                  <tr>
                    <td>XSP</td>
                    <td>Mini-SPX Index <sup>7</sup></td>
                    <td>$0.00/$0.04</td>
                  </tr>
                  </tbody>
                </SingleListedFeesTable>
              </OptionsFeesCol>
              <FeesCol md={12} lg={6}>
                <FeeRow title>
                  Miscellaneous Fees
                </FeeRow>
                <FeeRow>
                  <div>Overnight Domestic Mail</div>
                  <div>$50</div>
                </FeeRow>
                <FeeRow>
                  <div>Overnight International Mail</div>
                  <div>$100</div>
                </FeeRow>
              </FeesCol>
            </StyledRow>
            <StyledRow>
              <Disclaimer>
                <h5>Disclaimer</h5>
                <SmallPrint light>
                  tastytrade reserves the right to modify any fees and pass-through
                  any third party fees necessary to effectively manage your account
                  without prior notice. If you have any questions, please send an
                  email to customer support at <a href='mailto:support@tastytrade.com'>
                  support@tastytrade.com</a>.
                </SmallPrint>
                <ol>
                  <li>
                    Options exchanges assess an Options Regulatory Fee (referred
                    to as the “ORF fee”) on options transactions executed by its
                    member that clear in the customer range at Options Clearing
                    Corporation (“OCC”) regardless of the marketplace of execution.
                    The effective rate of the ORF fee is per contract with no
                    minimum per trade. The total regulatory charge will be
                    rounded up to the nearest penny. Please note Exchange Fees
                    are subject to change.
                  </li>
                  <li>
                    Per share fee charges may apply. Customers may pay additional
                    market-related expenses incurred in connection with orders
                    or related customer accounts, including cancellation fees,
                    product specific fees, regulatory fees, and expenses associated
                    with Make/Take fees.
                  </li>
                  <li>Exchange, clearing and regulatory Fees.</li>
                  <li>
                    FINRA levies a Trading Activity Fee (TAF) for sales covered
                    equity securities that tastytrade passes through to you.
                    The FINRA TAF for stock is currently $0.000119 per share with
                    a per-transaction cap of $5.95. The FINRA TAF for options
                    sales is currently $0.002 per contract.
                  </li>
                  <li>
                    When you sell an equity or an option, a regulatory fee is
                    included on your confirmation. tastytrade is required to pay
                    this fee to the applicable regulatory agency based on the
                    volume of securities sold. As of April 16, 2019, the sale of
                    equity securities is $22.10 for every $1,000,000 in sales.
                    tastytrade reserves the right to pass-through any additional
                    regulatory or exchange transaction fees.
                  </li>
                  <li>
                    When trading American Depository Receipts (ADR), fees may
                    apply and are passed through.
                  </li>
                  <li>
                    XSP contract fees are as follows $0.00 for less than 10 contracts per
                    leg and $0.04 for 10 contracts per leg or more.
                  </li>
                </ol>
              </Disclaimer>
            </StyledRow>
          </Container>
        </Section>
      </CommissionsAndFeesWrapper>
    </Layout>
  )
}

export default CommissionsAndFees;
